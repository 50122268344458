html,
body {
  height: 100%;
  width: 100%;
}
body {
  position: relative;
  background: url(../images/login_bg.jpg) no-repeat center center transparent;
  background-size: cover;
}
.login-main {
  position: relative;
}
.logo-tencent {
  position: absolute;
  top: 40px;
  left: 10%;
  width: 255px;
  height: 33px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: url(../images/logo_tencent.png) no-repeat 0 0 transparent;
}
.login {
  position: absolute;
  top: 250px;
  right: 0;
  width: 270px;
  height: auto;
}
.login .login-title {
  width: 270px;
  height: 42px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: url(../images/login_title.png) no-repeat 0 0 transparent;
  margin-bottom: 66px;
}
.login .login-panel {
  width: 270px;
  height: 160px;
  position: relative;
  background: url(../images/login_panel.png) no-repeat 0 0 transparent;
  margin-bottom: 20px;
}
.login .login-panel input {
  border: none;
  background-color: transparent;
  width: 220px;
  height: auto;
  color: #ffffff;
  line-height: 20px;
  padding: 10px 0;
  font-size: 14px;
  outline: none !important;
}
.login .login-panel input::-webkit-input-placeholder {
  color: #eeeeee;
}
.login .login-panel input:-moz-placeholder {
  color: #eeeeee;
}
.login .login-panel input::-moz-placeholder {
  color: #eeeeee;
}
.login .login-panel input:-ms-input-placeholder {
  color: #eeeeee;
}
.login .login-panel .email {
  position: absolute;
  top: 0;
  right: 5px;
}
.login .login-panel .password {
  position: absolute;
  top: 60px;
  right: 5px;
}
.login .login-panel .verify {
  position: absolute;
  top: 120px;
  right: 115px;
  width: 112px;
}
.login .login-panel .verify-code {
  position: absolute;
  top: 120px;
  right: 0;
  width: 99px;
  height: 40px;
  line-height: 0;
}
.login .login-panel .verify-code a,
.login .login-panel .verify-code img {
  display: block;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 52px;
  margin: 0;
  line-height: 42px;
  color: #C4C8CF;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
}
