@import './vendor/est/all.less';

html, body {
	height: 100%;
	width: 100%;
}

body {
	position: relative;
	background: url(../images/login_bg.jpg) no-repeat center center transparent;
	background-size: cover;
}

.login-main {
	position: relative;
}

.logo-tencent {
	.absolute(40px, _, _, 10%);
	.size(255px, 33px);
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap;
	background: url(../images/logo_tencent.png) no-repeat 0 0 transparent;
}

.login {
	.absolute(250px, 0, _, _);
	width: 270px;
	height: auto;

	.login-title {
		.size(270px, 42px);
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
		background: url(../images/login_title.png) no-repeat 0 0 transparent;
		margin-bottom: 66px;
	}

	.login-panel {
		.size(270px, 160px);
		position: relative;
		background: url(../images/login_panel.png) no-repeat 0 0 transparent;
		margin-bottom: 20px;

		input {
			border: none;
			background-color: transparent;
			.size(220px, auto);
			color: #ffffff;
			line-height: 20px;
			padding: 10px 0;
			font-size: 14px;
			outline: none !important;

			&::-webkit-input-placeholder {
				color: #eeeeee;
			}

			&:-moz-placeholder {
				color: #eeeeee;
			}

			&::-moz-placeholder {
				color: #eeeeee;
			}

			&:-ms-input-placeholder {
				color: #eeeeee;
			}
		}

		.email {
			.absolute(0, 5px, _, _);
		}

		.password {
			.absolute(60px, 5px, _, _);
		}

		.verify {
			.absolute(120px, 115px, _, _);
			width: 112px;
		}

		.verify-code {
			.absolute(120px, 0, _, _);
			.size(99px, 40px);
			line-height: 0;

			a, img {
				display: block;
			}
		}
	}
}

.footer {
	.absolute(_, _, 0, 0);
	.size(100%, 52px);
	margin: 0;
	line-height: 42px;
	color: #C4C8CF;
	background-color: rgba(0, 0, 0, 0.3);
	font-size: 14px;
}
